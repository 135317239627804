import { Link } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import TextContainer from "../components/text-container";

export default function ThankYou() {
  return (
    <Layout title="Thank You" description="Thank you for contacting us.  One of our staff will be in touch shortly." location="/thank-you">
      <TextContainer className="pt-16 text-center">
        <h1>Thank You</h1>
        <p>Thank you for contacting us.  One of our staff will be in touch shortly.</p>
        <Link to="/" className="btn">Back Home &rarr;</Link>
      </TextContainer>
    </Layout>
  )
}